<template>
  <v-btn
    v-if="listCreateConfig"
    :color="color"
    :buttonClass="buttonClass"
    class="mx-2"
    small
    @click="onClick"
  >
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  props: {
    listKey: String,
  },
  methods: {
    onClick() {
      this.$apopup.base({
        title: this.label,
        width: '40vw',
        bodySlot: this.listCreateConfig.popupComponent,
        disabledApply: this.disabledApply,
        applyOnEnter: false,
        applyCallback: this.apply,
      })
    },
    disabledApply(data) {
      const formData = !data ? {} : window.eagleLodash.cloneDeep(data)
      return this.listCreateConfig.disabledApply(formData)
    },
    async apply(data) {
      let result = null
      try {
        result = await this.listCreateConfig.createApi(data)
      } catch (error) {
        console.error(error)
        this.handleError(error)
        throw error
      } finally {
        if(result) this.handleResult(result)
      }
    },
    handleResult(result) {
      this.$snotify.success(null, this.$t('save.successfully'))

      const redirectRoute = this.getRedirectRoute(result)
      if(!redirectRoute) {
        this.listActions.index()
      }

      else {
        this.$router.push(redirectRoute)
      }
    },
    getRedirectRoute(result) {
      if(typeof this.listCreateConfig.redirect != 'function') return null
      return this.listCreateConfig.redirect(result)
    },
    handleError(error) {
      const status = error.status
      let message = this.$t('save.failure.data_incorrect')
      if(status != 400) {
        message = this.$t('error.unexpect')
      }

      this.$snotify.error(
        message,
        this.$t('save.failure')
      )
    },
  },
  computed: {
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`]
    },
    label() {
      if(this.listCreateConfig.label) return this.$t(this.listCreateConfig.label)
      return this.$t('action.create')
    },
    color() {
      return this.listCreateConfig.color || 'primary'
    },
    buttonClass() {
      return this.listCreateConfig.buttonClass
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`]
    },
    listCreateConfig() {
      return window.eagleLodash.get(this.listConfig, ['create'])
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
